<template>
  <div id="app">
    <!-- 背景图  -->
    <div class="bgImg" v-if="$route.path != '/aiMake'"></div>
    <!-- <router-view style="position: relative; z-index: 10" /> -->
    <keep-alive>
      <router-view
        style="position: relative; z-index: 10"
        v-if="$route.meta.keepAlive"
      ></router-view>
    </keep-alive>
    <router-view
      style="position: relative; z-index: 10"
      v-if="!$route.meta.keepAlive"
    ></router-view>
    <div class="bottom_fixed_css" v-if="!pathS.includes($route.path)">
      <div v-for="(item, index) in list" :key="index">
        <!-- v-if="item.stu || form.userName" -->
        <div v-if="item.stu || form.userName" @click="goPath(item.path)">
          <el-badge :value="item.name == '常用链接' ? '' : ''">
            <div
              class="img_list"
              :style="{ 'background-color': item.bgColor }"
              :class="{ widHei: item.id == 1 }"
            >
              <img :src="item.imgUrl" />
            </div>
          </el-badge>
          <div style="text-align: center;">{{ item.name }}</div>
        </div>
      </div>
      <div v-if="!form.userName" @click="goPath('/scanCode')">
        <div>
          <img class="tpixiang_css" :src="erimgUrl" />
          <div>扫码登陆</div>
        </div>
      </div>
      <div v-if="form.userName" @click="goPath('/personal')">
        <div>
          <avatar
            :imagesrc="form.photo"
            style="margin-bottom: 5px;"
            fontSize="4"
            BRnumber="0.6"
          />
          <div>{{ form.userName || '请选择' }}</div>
        </div>
      </div>
    </div>
    <!-- <div class="nowTime_css" v-if="!pathS.includes($route.path)">
      {{ nowTime }}
    </div> -->
    <div class="className_css" v-if="!pathS.includes($route.path)">
      {{ className }}
    </div>
  </div>
</template>
<script>
import api from '@/api/api'
import moment from 'moment'
export default {
  data() {
    return {
      pathS: [
        '/studentFile',
        '/largeScreen',
        '/principalScreen',
        '/principalScreenA',
        '/gradeScreen',
        '/gradeScreenA',
        '/gradeScreenB',
        '/aiMake',
      ],
      nowTime: '',
      className: localStorage.getItem('className'),
      erimgUrl: require('@/assets/erweimav.jpg'),
      time: '',
      form: localStorage.getItem('userInformation')
        ? JSON.parse(localStorage.getItem('userInformation'))
        : {},
      list: [
        // {
        //   name: "签到墙",
        //   imgUrl: require("@/assets/qiandao.png"),
        //   path: "/signInWall",
        //   stu: true,
        //   bgColor: "#c8ed1c",
        // },
        {
          name: '常用链接',
          imgUrl: require('@/assets/shipin2.png'),
          path: '/commonlyVideo',
          stu: true,
          bgColor: '#ed881c',
        },
        {
          name: '随机点名',
          imgUrl: require('@/assets/dianming1.png'),
          path: '/rollCall',
          stu: true,
          bgColor: '#ed32d0',
        },
        {
          name: '随机抽奖',
          imgUrl: require('@/assets/liwu.png'),
          path: '/luckyDraw',
          stu: false,
          bgColor: '#f34c64',
        },
        {
          name: '主页',
          id: 1,
          imgUrl: require('@/assets/hear-full.png'),
          path: '/',
          stu: false,
          bgColor: '#7b87df',
        },
        {
          name: '秒表计时',
          imgUrl: require('@/assets/countDown.png'),
          path: '/timing',
          stu: true,
          bgColor: '#ed32d0',
        },
        {
          name: '作业栏',
          imgUrl: require('@/assets/icon_xianxiazuoye.png'),
          path: '/jobField',
          stu: true,
          bgColor: '#9868a4',
        },
        // {
        //   name: "活动图片",
        //   imgUrl: require("@/assets/tupian.png"),
        //   path: "/movingPicture",
        //   stu: true,
        //   bgColor: "#37ce58",
        // },
      ],
    }
  },
  created() {
    this.getNowTime()
    let classId = localStorage.getItem('classID') || ''
    let smartCampusToken = localStorage.getItem('smartCampusToken') || ''
    if (!classId) {
      this.$router.push({ path: '/setClass' })
    }
    if (!smartCampusToken) {
      this.$router.push({ path: '/scanCode' })
    }
    const _this = this
    document.onkeydown = function(event) {
      // Shift + L   唤出设置班级
      if (window.event.shiftKey && window.event.keyCode == 76) {
        _this.$router.push({ path: '/setClass' })
      }
      // Shift + T   唤出设置年级（年级大屏）
      if (window.event.shiftKey && window.event.keyCode == 84) {
        console.log('唤出年级大屏')
        _this.$bus.$emit('gradeCut')
      }
      // Shift + I   唤出自动点评
      if (window.event.shiftKey && window.event.keyCode == 73) {
        // _this.$router.push({ path: '/aiMake' })
        let routeData = _this.$router.resolve({
          path: '/aiMake',
        })
        // 打开新页面
        window.open(routeData.href, '_blank')
      }
    }
  },
  mounted() {
    const _this = this
    this.$bus.$on('getItem', (e) => {
      _this.form = localStorage.getItem('userInformation')
        ? JSON.parse(localStorage.getItem('userInformation'))
        : {}
    })
    this.$bus.$on('getclassName', (e) => {
      _this.className = localStorage.getItem('className') || ''
    })
  },
  methods: {
    // 跳转
    goPath(path) {
      if (path == '/') {
        this.$bus.$emit('indexShua')
      }
      if (path) {
        this.$router.push({ path: path })
      }
    },
    async getNowTime() {
      let ttm = await api.getServerTime()
      this.time = moment(ttm || undefined).valueOf()
      this.nowTime = moment(this.time).format('MM-DD HH:mm:ss')
      localStorage.setItem(
        'newTime',
        moment(this.time).format('YYYY-MM-DD HH:mm:ss')
      )
      // localStorage.setItem('newTime',moment('2023-05-06 17:21:52').format('YYYY-MM-DD HH:mm:ss'))
      setInterval(() => {
        this.time += 1000
        this.nowTime = moment(this.time).format('MM-DD HH:mm:ss')
        localStorage.setItem(
          'newTime',
          moment(this.time).format('YYYY-MM-DD HH:mm:ss')
        )
      }, 1000)
    },
  },
}
</script>
<style>
@import url('/src/css/iconfont.css');
#app {
  position: relative;
  min-height: 100vh;
}
.is-fixed {
  right: 25px !important;
  height: 25px !important;
  line-height: 22px !important;
  border: none !important;
  font-size: 16px !important;
}
.bgImg {
  background-image: url('./assets/bg.jpg');
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
* {
  padding: 0;
  margin: 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

img,
input {
  border: 0;
}
html {
  overflow: hidden;
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  overflow: auto;
  height: 100%;
}
::-webkit-scrollbar {
  display: none;
}
.px1610 {
  max-width: 1610px;
  margin: 0 auto;
  overflow: hidden;
  padding: 50px 0 140px;
}
.bottom_fixed_css {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 20px 30px 12px;
  border-radius: 20px 20px 0 0;
  z-index: 99;
  left: 50%;
  transform: translateX(-50%);
}
.bottom_fixed_css > div > div {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 10px;
  cursor: pointer;
}
.img_list {
  width: 80px;
  height: 80px;
  border-radius: 15px;
  margin-bottom: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(55, 206, 88);
}
.img_list > img {
  width: 40px;
  height: 40px;
}
.widHei {
  width: 95px !important;
  height: 95px !important;
}
.widHei > img {
  width: 60px !important;
  height: 60px !important;
}
.tpixiang_css {
  width: 80px;
  height: 80px;
  border-radius: 15px;
  margin-bottom: 5px;
}
.nowTime_css {
  position: fixed;
  z-index: 20;
  bottom: 50px;
  right: 50px;
  color: rgb(255, 0, 0);
  font-size: 42px;
  font-weight: bold;
}
.className_css {
  position: fixed;
  z-index: 20;
  bottom: 50px;
  left: 50px;
  color: rgb(0, 0, 0);
  font-size: 42px;
  font-weight: bold;
}
</style>
