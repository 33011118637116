import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  // 主页
  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/index/index.vue'),
    // meta: {
    //   keepAlive: true // 需要缓存页面
    // }
  },
  // 签到墙
  {
    path: '/signInWall',
    name: 'SignInWall',
    component: () => import('@/views/signInWall/index.vue'),
  },
  // 常用视频
  {
    path: '/commonlyVideo',
    name: 'CommonlyVideo',
    component: () => import('@/views/commonlyVideo/index.vue'),
  },
  // 随机点名
  {
    path: '/rollCall',
    name: 'RollCall',
    component: () => import('@/views/rollCall/index.vue'),
  },
  // 随机抽奖
  {
    path: '/luckyDraw',
    name: 'LuckyDraw',
    component: () => import('@/views/luckyDraw/index.vue'),
  },
  // 秒表计时
  {
    path: '/timing',
    name: 'Timing',
    component: () => import('@/views/timing/index.vue'),
  },
  // 作业栏
  {
    path: '/jobField',
    name: 'JobField',
    component: () => import('@/views/jobField/index.vue'),
  },
  // 活动图片
  {
    path: '/movingPicture',
    name: 'MovingPicture',
    component: () => import('@/views/movingPicture/index.vue'),
  },
  // 扫码登陆
  {
    path: '/scanCode',
    name: 'ScanCode',
    component: () => import('@/views/scanCode/index.vue'),
  },
  // 个人信息
  {
    path: '/personal',
    name: 'Personal',
    component: () => import('@/views/personal/index.vue'),
  },
  // 设置班级
  {
    path: '/setClass',
    name: 'SetClass',
    component: () => import('@/views/setClass/index.vue'),
  },
  // 评价报告
  {
    path: '/studentFile',
    name: 'StudentFile',
    component: () => import('@/views/studentFile/studentList.vue'),
  },
  // 数据大屏
  {
    path: '/largeScreen',
    name: 'largeScreen',
    component: () => import('@/views/largeScreen/largeScreen.vue'),
  },
  // 年级大屏
  {
    path: '/gradeScreenB',
    name: 'gradeScreenB',
    component: () => import('@/views/gradeScreenB/index.vue'),
  },
  // 年级大屏A
  {
    path: '/gradeScreenA',
    name: 'gradeScreenA',
    component: () => import('@/views/gradeScreenA/index.vue'),
  },
  // 年级大屏B
  {
    path: '/gradeScreen',
    name: 'gradeScreen',
    component: () => import('@/views/gradeScreen/index.vue'),
  },
  // 年级大屏B
  {
    path: '/gradeScreenC',
    name: 'gradeScreenC',
    component: () => import('@/views/gradeScreenC/index.vue'),
  },
  // 校长大屏
  {
    path: '/principalScreen',
    name: 'principalScreen',
    component: () => import('@/views/principalScreen/principalScreen.vue'),
  },
  // 校长大屏A
  {
    path: '/principalScreenA',
    name: 'principalScreenA',
    component: () => import('@/views/principalScreenA/principalScreen.vue'),
  },
  // 生成评语
  {
    path: '/aiMake',
    name: 'aiMake',
    component: () => import('@/views/aiMake/index.vue'),
  },
  // // 常用视频
  // {
  //   path: '/details/:id/:index/:type',
  //   name: 'Details',
  //   component: () => import('@/views/index/details.vue'),
  // },
]

const router = new VueRouter({
  routes,
})
// 不加会抛错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
// 路由拦截
router.beforeEach((to, from, next) => {
  // 没有绑定班级就跳转绑定班级
  let classId = localStorage.getItem('classID')
  if (to.path == '/studentFile' && to.query.ClassID && to.query.StudentID) {
    next()
  } else if (!classId && to.path !== '/setClass') {
    next({ path: '/setClass' })
  } else if (
    to.path !== '/setClass' &&
    !localStorage.getItem('smartCampusToken') &&
    to.path !== '/scanCode'
  ) {
    next({ path: '/scanCode' })
  } else {
    next()
  }
})
export default router
