import axios from 'axios'
// const apiUrl = 'http://192.168.99.15:12001/webapi4/'
// console.log(window.g.ApiUrl, 5555)
// const apiUrl = 'https://www.lssyex.net/webapi4/'
const apiUrl = window.g.ApiUrl
// const apiUrl = 'http://192.168.99.105:13000/webapi4/'
// const apiUrl = '/api/'
const contenttype = 'application/x-www-form-urlencoded'
import { Message } from 'element-ui'
import qs from 'qs'
import md5 from '@/MD5/md5.js'
import moment from 'moment'

// 获取服务器时间
async function getServerTime() {
  let res = await axios({
    method: 'GET',
    timeout: 180000,
    url: apiUrl + 'ServerTime',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Token: Token(),
    },
  })
  if (res.data.code === 200) {
    return res.data.data
  } else if (res.data.code !== 201) {
    Message.error(res.data.message)
  }
}

const Secretkey = async () => {
  let time = localStorage.getItem('newTime') || ''
  let formatTime = moment(time || undefined).format('YYYYMMDDHHmm')
  return md5(formatTime + 'LeLe2023').toLocaleUpperCase()
}
const Token = () => {
  if (localStorage.getItem('smartCampusToken')) {
    return localStorage.getItem('smartCampusToken')
  } else {
    return ''
  }
}
// const Token = () => {
// 	return 'lg1enUVcFv2Cp2MzQ8dxPpzf+W+dU/QGe31/TafrRzAsutlMv5QeH6jlgYzLVZ/y'
// }

async function get(url, data, content) {
  let res = await axios({
    method: 'GET',
    timeout: 180000,
    url: apiUrl + url,
    headers: {
      'Content-Type': content ? content.content : contenttype,
      Secretkey: await Secretkey(),
      Token: Token(),
    },
    params: data,
  })
  if (res.data.code === 200) {
    return { data: res.data.data, headers: res.headers }
  } else if (res.data.code !== 201) {
    Message.error(res.data.message)
  }
}
async function post(url, data, content) {
  for (let i in data) {
    if (typeof data[i] == 'string') {
      data[i] = data[i].replace(/</g, '')
    }
  }
  let res = await axios({
    method: 'POST',
    timeout: 180000,
    url: apiUrl + url,
    headers: {
      'Content-Type': content ? content.content : contenttype,
      Secretkey: await Secretkey(),
      Token: Token(),
    },
    data: qs.stringify(data),
  })
  if (res.data.code === 200) {
    return { data: res.data.data, headers: res.headers }
  } else if (res.data.code !== 201) {
    Message.error(res.data.message)
  }
}
async function postA(url, data, content) {
  for (let i in data) {
    if (typeof data[i] == 'string') {
      data[i] = data[i].replace(/</g, '')
    }
  }
  let res = await axios({
    method: 'POST',
    timeout: 180000,
    url: apiUrl + url,
    headers: {
      'Content-Type': content ? content.content : contenttype,
      Secretkey: await Secretkey(),
      Token: Token(),
    },
    data: data,
  })
  if (res.data.code === 200) {
    return { data: res.data.data, headers: res.headers }
  } else {
    //2024-3-13 将res.data.code !== 201改为else
    Message.error(res.data.message)
  }
}
async function del(url, data, content) {
  let res = await axios({
    method: 'DELETE',
    timeout: 180000,
    url: apiUrl + url,
    headers: {
      'Content-Type': content ? content.content : contenttype,
      Secretkey: await Secretkey(),
      Token: Token(),
    },
    data: data,
  })
  if (res.data.code === 200) {
    return { data: res.data.data, headers: res.headers }
  } else if (res.data.code !== 201) {
    Message.error(res.data.message)
  }
}
async function put(url, data, content) {
  for (let i in data) {
    if (typeof data[i] == 'string') {
      data[i] = data[i].replace(/</g, '')
    }
  }
  let res = await axios({
    method: 'PUT',
    timeout: 180000,
    url: apiUrl + url,
    headers: {
      'Content-Type': content ? content.content : contenttype,
      Secretkey: await Secretkey(),
      Token: Token(),
    },
    data: data,
  })
  if (res.data.code === 200) {
    return { data: res.data.data, headers: res.headers }
  } else if (res.data.code !== 201) {
    Message.error(res.data.message)
  }
}
export default {
  get,
  post,
  postA,
  del,
  put,
  getServerTime,
  apiUrl,
}
