import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './css/iconfont.css'
Vue.prototype.$axios = axios 
Vue.config.productionTip = false
import "@/utils/rem.js"
import avatar from './components/Avatar'
Vue.component('avatar', avatar)

Vue.prototype.contenttype = 'application/x-www-form-urlencoded'
Vue.prototype.$bus = new Vue()

Vue.use(ElementUI);
new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
