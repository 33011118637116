<!-- 头像 -->
<template>
	<div @click="click" :style="{
				width:fontSize + 'rem',
				height:fontSize + 'rem',
				borderRadius:BRnumber + 'rem'
			}">
		<!-- 图片显示 -->
		<img v-if="imageError || imagesrc == ''" :style="{
				width:fontSize + 'rem',
				height:fontSize + 'rem',
				borderRadius:BRnumber + 'rem'
			}" :src="defaultSrc" style="display: block;flex-shrink: 0;object-fit: cover;" />
		<img v-else :style="{
				width:fontSize + 'rem',
				height:fontSize + 'rem',
				borderRadius:BRnumber + 'rem'
			}" @error="imageError = true" :src="imagesrc" style="display: block;flex-shrink: 0;object-fit: cover;">
	</div>
</template>

<script>
	export default {
		name: "Avatar",
		props: {
			// 头像大小
			fontSize: {
				type: [Number, String],
				default: '10'
			},
			// 图片圆角
			BRnumber: {
				type: [Number, String],
				default: '1000'
			},
			// 图片地址
			imagesrc: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				imageError: false, //图片是否加载失败
				defaultSrc: require('@/assets/defaultSrc.png')
			}
		},
		methods:{
			click() {
			    this.$emit('click')
			}
		}
	}
</script>
