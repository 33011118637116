var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
			width:_vm.fontSize + 'rem',
			height:_vm.fontSize + 'rem',
			borderRadius:_vm.BRnumber + 'rem'
		}),on:{"click":_vm.click}},[(_vm.imageError || _vm.imagesrc == '')?_c('img',{staticStyle:{"display":"block","flex-shrink":"0","object-fit":"cover"},style:({
			width:_vm.fontSize + 'rem',
			height:_vm.fontSize + 'rem',
			borderRadius:_vm.BRnumber + 'rem'
		}),attrs:{"src":_vm.defaultSrc}}):_c('img',{staticStyle:{"display":"block","flex-shrink":"0","object-fit":"cover"},style:({
			width:_vm.fontSize + 'rem',
			height:_vm.fontSize + 'rem',
			borderRadius:_vm.BRnumber + 'rem'
		}),attrs:{"src":_vm.imagesrc},on:{"error":function($event){_vm.imageError = true}}})])}
var staticRenderFns = []

export { render, staticRenderFns }